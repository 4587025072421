import Vue from "vue";
import App from '@/App';
import router from '@/common/router';
const init = function () {
  new Vue({
    el: '#app',
    router,
    render: (e) => e(App)
  });
};
let propertyPages = ['/propertyManager/home', '/propertyManager/login', '/propertyManager/rule', '/propertyManager/orderList', '/propertyManager/performanceDetail'];
let groupPages = ['/groupManager/home', '/groupManager/login', '/groupManager/rule', '/groupManager/orderList', '/groupManager/userList'];
export const isPropertyManagerPage = propertyPages.includes(location.pathname);
export const isGroupManagerPage = groupPages.includes(location.pathname);
if (location.pathname == '/message') {
  init();
} else if (isPropertyManagerPage || isGroupManagerPage) {
  // 物业网移动端
  import('vant/lib/index.css');
  import("vant").then(vant => {
    vant.Toast.setDefaultOptions({ duration: 1000, forbidClick: true, position: "top" });
    Vue.use(vant);
    init();
  });
} else {
  import('element-ui/lib/theme-chalk/index.css');
  import('element-ui').then(ElementUI => {
    Vue.use(ElementUI);
    init();
  });
}

